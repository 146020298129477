<template>
  <div class="application">
    <mobile v-if="isMobileVisible" @toggle-mobile="toggleMobile"></mobile>
    <navbar @toggle-mobile="toggleMobile"></navbar>
    <div class="a-four">
      <img src="../assets/banners/banner-chrome.png" alt="">
      <div class="text">
        <div class="h1"><h1>Mention Légal HBC</h1></div>
        <div class="h2"><h2>Mentions Légales de hbc-group.fr</h2></div>
        <div class="h2"><h2>1. Informations légales</h2></div>
        <span>Dénomination : HIGH BASE CODE </span>
        <span>Forme juridique : Société à responsabilité limitée (SARL) </span>
        <span>SIREN : 978 443 810 </span>
        <span>SIRET du siège social : 978 443 810 00012 </span>
        <span>Numéro de TVA Intracommunautaire : FR86 978 443 810 </span>
        <span>Code NAF/APE : 62.01Z - Programmation informatique </span>
        <span>Adresse : 45 BD LEAU, 13008 MARSEILLE 8 </span>
        <span>Date de création : 03/08/2023 </span>
        <div class="h2"><h2>2. Responsable de la publication </h2></div>
        <span>M. Bastien Rapaud, en sa qualité de Gérant. </span>
        <div class="h2"><h2>3. Hébergement</h2></div>
        <span>Les détails de l'entreprise d'hébergement de ce site seront à compléter ici. </span>
        <div class="h2"><h2>4. Contact </h2></div>
        <span>Pour toute question concernant ce site ou ces Mentions Légales, veuillez nous contacter à support@hbc-group.fr ou à notre adresse postale. </span>
        <div class="h2"><h2>5. Propriété</h2></div>
        <span>Le site hbc-group.fr et etudik.fr sont la propriété de HIGH BASE CODE SARL. </span>
        <div class="h2"><h2>6. Informations sur les actionnaires </h2></div>
        <span>M. Hervé Duong est actionnaire à hauteur de 50% de HIGH BASE CODE SARL. </span>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import Navbar from "@/components/navbar.vue";
import mobile from "@/components/mobile.vue";
import Foot from "@/components/foot.vue";

export default {
  name: 'mention',
  components: {
    Foot,
    mobile,
    Navbar,
  },
  data() {
    return {
      isMobileVisible: false,
    };
  },
  methods: {
    toggleMobile() {
      this.isMobileVisible = !this.isMobileVisible;
    },
  },
}
</script>

<style scoped>
.a-four{
  display: flex;
  width: 100%;
  padding: 50px;
  justify-content: center;
  align-items: center;
  background: black;
  flex-direction: column;
}

.a-four img{
  display: flex;
  width: 100%;
  max-width: 70%;
  object-fit: cover;
}

.a-four .text{
  display: flex;
  width: 100%;
  max-width: 21cm;
  flex-direction: column;
  padding: 20px;
  color: white;
  margin-top: 50px;
}

.h1 h1{
  font-family: 'Lexend Deca', sans-serif;
}

.h1{
  display: flex;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.h2{
  display: flex;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
}

@media  (max-width: 500px) {
  .a-four img{
    display: none;
  }
  .a-four .text{
    padding: 0;
  }
}
</style>
