<template>
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
  <router-view />
</template>
<script>
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  font-family: 'Work Sans', sans-serif;
}

.application{
  display: flex;
  width: 100%;
  flex-direction: column;
}

body, #app{
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  background-color: #F8F8F7;
}

h1, h2, h3, h4, h5, h6{
  font-family: 'Lato', sans-serif;
}
</style>
