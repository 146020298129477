<template>
  <mobile v-if="isMobileVisible" @toggle-mobile="toggleMobile"></mobile>
  <navbar @toggle-mobile="toggleMobile"></navbar>
  <div class="art-nav">
    <span class="material-symbols-outlined" @click="toggleSidebar"> menu </span>
    <a href="" class="btn-sub">S'abonner à 1€</a>
  </div>
  <div class="sidebar" v-if="isSidebarVisible">
    <div class="text">
      <h4 @click="toggleSidebar"><span class="material-symbols-outlined"> close </span>Fermer</h4>
    </div>

    <div class="input">
      <span class="material-symbols-outlined"> search </span>
      <input type="text" name="search" id="search" placeholder="Rechercher un article">
    </div>

    <div class="text">
      <small>Articles récent</small>
    </div>
    <div class="list">
      <a href="#">Apple, Meta, Google et Microsoft présentent leurs nouvelles innovations : iPhones 13, Meta Quest 2, Pixel Watch et Surface Laptop Studio</a>
      <a href="#">Apple, Meta, Google et Microsoft présentent leurs nouvelles innovations : iPhones 13, Meta Quest 2, Pixel Watch et Surface Laptop Studio</a>
    </div>
  </div>
  <section>
    <div class="article">
      <small>Dernier article</small>
      <h1 class="arth">Apple, Meta, Google et Microsoft présentent leurs nouvelles innovations : iPhones 13, Meta Quest 2, Pixel Watch et Surface Laptop Studio</h1>
      <div class="embed">
        <div class="title">
          <h1>Apple annonce ses nouveaux produits durant son événement de septembre :</h1>
        </div>
        <p>Apple a présenté sa série de smartphones iPhone 13, une nouvelle série de tablettes iPad et iPad Mini, et une nouvelle version de sa boîte à musique, l'iPod touch. Les nouveautés incluent des améliorations de performance, des caméras améliorées, et des fonctionnalités nouvelles telles que le mode ProRes Video sur les iPhones 13 Pro et Pro Max.  </p>
      </div>
      <div class="embed">
        <div class="title">
          <h1>Meta annonce la sortie de la version mise à l'échelle de sa plateforme de réalité virtuelle :</h1>
        </div>
        <p>Meta Platforms, anciennement connu sous le nom de Facebook, a annoncé la sortie prochaine de la version mise à l'échelle de sa plateforme de réalité virtuelle, Meta Quest 2. La nouvelle version offre une résolution améliorée, une fonctionnalité de suivi des yeux améliorée, et un prix plus bas que son prédécesseur, le Quest 1.  </p>
      </div>
      <div class="embed">
        <div class="title">
          <h1>Google annonce un nouveau produit pour la santé :</h1>
        </div>
        <p>Google a annoncé une nouvelle montre de santé nommée Pixel Watch, qui devrait être lancée prochainement. La montre est conçue en collaboration avec Fossil et offre des fonctionnalités telles que la détection du sommeil, des notifications de santé, et une intégration avec les services Google Fit et Google Assistant.  </p>
      </div>
      <div class="embed">
        <div class="title">
          <h1>Microsoft annonce une nouvelle version de son ordinateur portable :</h1>
        </div>
        <p>Microsoft a annoncé une nouvelle version de son ordinateur portable, la Surface Laptop Studio. La nouvelle machine offre une conception plus puissante que les modèles précédents, une écran 14,4 pouces avec une définition de 2880 x 1920 pixels, et une fonctionnalité Tablet Mode qui permet de passer facilement d'un mode clavier à un mode tableau. </p>
      </div>
      <div class="embed">
        <div class="title">
          <h1>Amazon annonce une nouvelle version de son assistant intelligent :</h1>
        </div>
        <p>Amazon a annoncé une nouvelle version de son assistant intelligent, nommée Echo Dot (4th Gen). La nouvelle version offre une conception ronde, une qualité sonore améliorée, et une intégration avec une gamme plus large de fonctionnalités smart home. Ces sont quelques-unes des nouvelles intéressantes dans le domaine de la technologie pour aujourd'hui. Il y a certainement beaucoup d'autres nouvelles à suivre, et je suis heureux de vous les partager si cela vous intéresse. Si vous avez des questions ou des commentaires, n'hésitez pas à les laisser ci-dessous. </p>
      </div>
    </div>
  </section>
  <foot></foot>
</template>

<script>
import Foot from "@/components/foot.vue";
import mobile from "@/components/mobile.vue";
import Navbar from "@/components/navbar.vue";

export default {
  name : "art",
  components : {
    Foot,
    mobile,
    Navbar,
  },
  data() {
    return {
      isMobileVisible: false,
      isSidebarVisible: false,
    };
  },
  methods: {
    toggleMobile() {
      this.isMobileVisible = !this.isMobileVisible;
    },

    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible;
    },
  },
}
</script>

<style scoped>
section{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 100px 20px;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.article{
  display: flex;
  width: 100%;
  max-width: 21cm;
  flex-direction: column;
  grid-gap: 100px;
  padding-top: 50px;
}

.arth {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 35px;
  border-bottom: solid 1px #1A1D1F;
  text-align: center;
  padding: 0 20px;
  color: #072653;
  padding-bottom: 50px;
}

.embed{
  display: flex;
  flex-direction: column;
}

.embed h1{
  color: #072653;
  font-family: 'Lexend Deca', sans-serif;
}

.embed .title{
  display: flex;
  color: #072653;
  margin-bottom: 20px;
  font-size: 20px;
}

.embed p{
  display: flex;
  font-size: 20px;
  color: #072653;
  text-align: justify;
}



.art-nav{
  display: flex;
  width: 100%;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  z-index: 2;
  padding: 0 100px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  grid-gap: 15px;
  position: fixed;
  background-color: white;
}

.art-nav span{
  cursor: pointer;
  font-size: 30px;
}

.btn-sub{
  display: flex;
  color: #072653;
  background: #FFCD1D;
  border-radius: 50px;
  padding: 10px 20px;
}

.input{
  display: flex;
  align-items: center;
  max-width: 350px;
  width: 100%;
  padding: 10px 20px;
  border-radius: 50px;
  background-color: #F8F8F7;
}

.input input{
  display: flex;
  background: none;
  border: none;
  outline: none;
  width: 100%;
  padding: 0 10px;
}

small{
  color: #072653;

}
.sidebar{
  display: flex;
  z-index: 2;
  margin-top: 50px;
  top: 0;
  left: 0;
  width: 400px;
  min-width: 400px;
  background-color: #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  height: 100%;
  position: fixed;
  flex-direction: column;
  align-items: center;
  grid-gap: 20px;
  padding: 10px 0;
}

.sidebar .text{
  display: flex;
  width: 100%;
  justify-content: left;
  padding: 0 20px;
  align-items: center;
}

.text h4{
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #072653;
}

.list{
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  max-height: 100%;
  padding: 0 20px;
  align-items: center;
  padding-bottom: 100px;
}

.list a{
  display: flex;
  color: #072653;
  padding: 5px 10px;
}

.list a:hover{
  background-color: #F8F8F7;
  border-radius: 15px;
}

@media (max-width: 1000px) {
  .art-nav{
    padding: 0 20px;
  }
}

</style>
