<template>
    <div class="mobile">
      <div class="navbar">
        <div></div>
        <span class="material-symbols-outlined" @click="closeMobile"> close </span>
      </div>
      <div class="list">
        <a href="/"><h1>Accueil</h1></a>
        <h1 @click="toggleArticles">Articles</h1>
        <h1 @click="toggleServices">Nos Services</h1>
        <h1 @click="togglePodcasts">Podcasts</h1>
        <a href="#"><h1>IT Jobs</h1></a>
        <a href="#" class="account"><h1>Mon espace</h1></a>
      </div>
    </div>


  <div class="articles" v-show="showArticles">
    <div class="navbar">
      <span class="material-symbols-outlined" @click="toggleArticles"> arrow_back_ios </span>
      <span class="material-symbols-outlined" @click="closeMobile"> close </span>
    </div>
    <div class="list">
      <h1>ARTICLES HBC</h1>
      <a href="#" class="link_mobile">Dernier article</a>
      <a href="#" class="link_mobile">Tout voir</a>
      <div class="space"></div>
      <h1>Veille Technologique</h1>
      <a href="/articles/veille-technologique" class="link_mobile">Tout voir</a>
    </div>
  </div>


  <div class="services" v-show="showServices">
    <div class="navbar">
      <span class="material-symbols-outlined" @click="toggleServices"> arrow_back_ios </span>
      <span class="material-symbols-outlined" @click="closeMobile"> close </span>
    </div>
    <div class="list">
      <h1>SITE WEB</h1>
      <a href="#" class="link_mobile">Création de site web</a>
      <a href="#" class="link_mobile">Conception d'une ADN visuel</a>
      <div class="space"></div>
      <h1>PC</h1>
      <a href="#" class="link_mobile">Dépannage informatique</a>
      <a href="#" class="link_mobile">Configuration PC</a>
    </div>
  </div>

  <div class="podcasts" v-show="showPodcasts">
    <div class="navbar">
      <span class="material-symbols-outlined" @click="togglePodcasts"> arrow_back_ios </span>
      <span class="material-symbols-outlined" @click="closeMobile"> close </span>
    </div>
    <div class="list">
      <h1>HBC DIRECT</h1>
      <a href="#" class="link_mobile">Diffusion en direct</a>
      <div class="space"></div>
      <h1>REDIFFUSIONS</h1>
      <a href="#" class="link_mobile">Dernière diffusion</a>
      <a href="#" class="link_mobile">Tout voir</a>
      <div class="space"></div>
      <h1>Podcats</h1>
      <a href="#" class="link_mobile">Dernier Podcast</a>
      <a href="#" class="link_mobile">Tout voir</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mobile',
  components: {},
  data() {
    return {
      showArticles: false,
      showServices: false,
      showPodcasts: false,
    };
  },
  methods: {
    toggleArticles() {
      this.showArticles = !this.showArticles;
    },
    toggleServices() {
      this.showServices = !this.showServices;
    },
    togglePodcasts() {
      this.showPodcasts = !this.showPodcasts;
    },
    closeMobile() {
      this.$emit('toggle-mobile');
    },
  },
}
</script>

<style scoped>
.mobile {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #1F2226;
  position: fixed;
  z-index: 5;
  padding: 20px;
  flex-direction: column;
}

.navbar{
  display: flex;
  width: 100%;
  height: 50px;
  color: white;
  justify-content: space-between;
}

.navbar span{
  cursor: pointer;
}

.list{
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  padding: 0 20px;
  color: white;
}

.list a{
  color: white;
}

.list h1{
  margin-bottom: 25px;
  font-family: 'Lexend Deca', sans-serif;
  cursor: pointer;
}

.link_mobile{
  display: flex;
  margin-bottom: 25px;
}

.space{
  display: flex;
  margin-bottom: 50px;
}

.articles{
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #1F2226;
  position: fixed;
  z-index: 6;
  padding: 20px;
  flex-direction: column;
}

.services{
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #1F2226;
  position: fixed;
  z-index: 6;
  padding: 20px;
  flex-direction: column;
}

.podcasts{
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #1F2226;
  position: fixed;
  z-index: 6;
  padding: 20px;
  flex-direction: column;
}

.account{
  display: flex;
  padding-top: 20px;
  border-top: solid 3px white;
}
</style>
