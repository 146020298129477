<template>
    <nav>
      <a href="/" class="logo"><img src="../assets/logos/icon-blanc.png" alt=""></a>


      <div class="dropdown">
        <a href="/" class="dropdown-btn"><small>Accueil</small></a>
      </div>

      <div class="dropdown">
        <button class="dropdown-btn">Articles</button>
        <div class="dropdown-content">
          <div class="box">
            <span class="title">ARTICLES HBC</span>
            <small class="text">Chaque semaine, nous proposons des articles pertinents en rapport avec les actualités de l'entreprise.</small>
            <a href="#"><small>Dernier article</small></a>
            <a href="#"><small>Tout voir</small></a>
          </div>
          <div class="box">
            <span class="title">VEILLE TECHNOLOGIQUE</span>
            <small class="text">Notre équipe s'engage à vous informer régulièrement sur toutes les dernières évolutions dans le domaine de l'informatique & technologique.</small>
            <a href="/articles/veille-technologique"><small>Tout voir</small></a>
          </div>
        </div>
      </div>


      <div class="dropdown">
        <button class="dropdown-btn">Nos services</button>
        <div class="dropdown-content">
          <div class="box">
            <span class="title">SITE WEB</span>
            <small class="text">Nous offrions des services informatiques dans le domaine du développement web et la conception d'une identité visuelle.</small>
            <a href="#"><small>Création de site web</small></a>
            <a href="#"><small>Conception d'une ADN visuel</small></a>
          </div>
          <div class="box">
            <span class="title">PC</span>
            <small class="text">Nous proposons des services de dépannage et configuration informatique en personne dans l'ensemble de la région PACA.</small>
            <a href="#"><small>Dépannage informatique</small></a>
            <a href="#"><small>Configuration PC</small></a>
          </div>
        </div>
      </div>


      <div class="dropdown">
        <button class="dropdown-btn">Podcasts</button>
        <div class="dropdown-content">
          <div class="box">
            <span class="title">HBC DIRECT</span>
            <small class="text">Retrouvez en direct les membres de l'équipe HBC en train de discuter de nombreux sujets.</small>
            <a href="#"><small>Diffusion en direct</small></a>
          </div>
          <div class="box">
            <span class="title">REDIFFUSIONS</span>
            <small class="text">Toutes les rediffusions des directs se trouvent ici.</small>
            <a href="#"><small>Dernière rediffusion</small></a>
            <a href="#"><small>Tout voir</small></a>
          </div>
          <div class="box">
            <span class="title">PODCASTS</span>
            <small class="text">Retrouvez des podcasts déjà enregistrés sur divers sujets.</small>
            <a href="#"><small>Dernier podcast</small></a>
            <a href="#"><small>Tout voir</small></a>
          </div>
        </div>
      </div>

      <div class="dropdown">
        <a href="" class="dropdown-btn"><small>IT Jobs</small></a>
      </div>


      <div class="right">
        <a href="#">
          <span class="notification">0</span>
          <span class="material-symbols-outlined"> shopping_bag </span>
        </a>

        <!--Quand l'utilisateur n'est  pas connecter alors afficher-->
        <a href="espace-de-connexion" class="account"><span class="material-symbols-outlined"> person </span></a>


        <!--Quand l'utilisateur est connecter alors afficher-->

        <a href="/mon-espace" class="name_account"><small>Hervé Duong</small></a>

        <span class="material-symbols-outlined hamburger" @click="toggleMobile"> menu </span>
      </div>
    </nav>
</template>

<script>
export default {
  name: 'navbar',
  components: {
  },
  methods: {
    toggleMobile() {
      this.$emit('toggle-mobile');
    },
  },
}
</script>

<style scoped>
nav {
  display: flex;
  width: 100%;
  position: fixed;
  min-height: 50px;
  background-color: #1A1D1F;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

nav .logo {
  display: flex;
  position: absolute;
  left: 0;
  margin-left: 20px;
}

nav img {
  display: flex;
  min-width: 30px;
  max-width: 30px;
  object-fit: cover;
}

.right {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  margin-right: 20px;
}

.right a,
.right span {
  display: flex;
  position: relative;
  color: white;
  cursor: pointer;
}

.right a {
  padding-right: 5px;
}

.right a .notification {
  display: flex;
  min-width: 15px;
  max-width: 15px;
  max-height: 15px;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  min-height: 15px;
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  border-radius: 50%;
}

.right .hamburger {
  display: none;
  margin-left: 25px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: fixed;
  left: 0;
  background-color: #1F2226;
  color: white;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  padding: 20px;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 50px;
}

.dropdown-btn {
  color: white;
  background: none;
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  width: 120px;
  border: none;
  cursor: pointer;
}

.box{
  display: flex;
  min-width: 200px;
  flex-direction: column;
  color: white;
  position: relative;
  margin: 0 25px;
}

.box .title{
  font-weight: bold;
  margin-bottom: 15px;
  font-family: 'Lexend Deca', sans-serif;
}

.box a{
  color: white;
  margin-bottom: 20px;
  font-weight: bold;
}

.box .text{
  display: flex;
  width: 100%;
  max-width: 350px;
  margin-bottom: 30px;
  font-style: italic;
  border-bottom: solid 1px white;
  padding-bottom: 20px;
  min-height: 70px;
}


.box a:hover{
  text-decoration: underline;
}

.dropdown:hover .dropdown-content {
  display: flex;
}

.right .account{
  display: flex;
  margin-left: 15px;
}

.right .name_account{
  display: none;
  padding-left: 20px;
  border-left: 2px solid white;
  color: white;
  margin-left: 15px;
  font-weight: bold;
}

@media (max-width: 950px){
  .right .hamburger{
    display: flex;
  }

  .right .name_account{
    display: none;
  }

  .dropdown{
    display: none;
  }
}
</style>
