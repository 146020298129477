import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/Index.vue';
import Signin from '../views/Signin.vue';
import Signup from "@/views/Signup.vue";
import Cgu from "@/views/Cgu.vue";
import Mention from "@/views/Mention.vue";
import Account from "@/views/Account.vue";
import Art from "@/views/Art.vue";

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/espace-de-connexion',
    name: 'signin',
    component: Signin
  },
  {
    path: '/espace-inscription',
    name: 'signup',
    component: Signup
  },
  {
    path: '/cgu',
    name: 'cgu',
    component: Cgu
  },
  {
    path: '/mention-legal',
    name: 'mention',
    component: Mention
  },
  {
    path: '/mon-espace',
    name: 'account',
    component: Account
  },
  {
    path: '/articles/veille-technologique',
    name: 'art',
    component: Art
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
