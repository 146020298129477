<template>
  <div class="application">
    <mobile v-if="isMobileVisible" @toggle-mobile="toggleMobile"></mobile>
    <navbar @toggle-mobile="toggleMobile"></navbar>
    <section class="form">
      <form action="">
        <h1>Création de compte.</h1>
        <small class="desc">Les informations sont traitées de manière confidentielle <br>et ne seront ni partagées ni divulguées.<br><br>*Champ Obligatoire</small>
        <div class="dual">
          <input type="text" name="lastname" id="lastname" placeholder="Nom*" required>
          <div class="space"></div>
          <input type="text" name="firstname" id="firstname" placeholder="Prénom*" required>
        </div>
        <input type="email" name="email" id="email" placeholder="Adresse électronique*" required>
        <input type="password" name="password" id="password" placeholder="Mot de passe*" required>
        <input type="password" name="confirm" id="confirm" placeholder="Confirmation*" required>
        <input type="text" name="position" id="position" placeholder="Adresse*" required>
        <div class="dual">
          <input type="text" name="city" id="city" placeholder="Ville*" required>
          <div class="space"></div>
          <input type="text" name="postal" id="postal" placeholder="Code Postal*" required>
        </div>
        <div class="save">
          <input type="checkbox" name="accept" id="save" required>
          <label for="save"><small>Accepter la CGU & CG.</small></label>
        </div>
        <button><span class="material-symbols-outlined"> arrow_right_alt </span></button>
        <a href="/help"><small class="blue">Vous rencontrez un problème ?</small></a>
        <div class="account_"><small>Déjà un compte ?</small><a href="/espace-de-connexion"><small class="blue">Connectez-vous dès maintenant.</small></a></div>
      </form>
    </section>
    <foot></foot>
  </div>
</template>

<script>
import Navbar from "@/components/navbar.vue";
import mobile from "@/components/mobile.vue";
import Foot from "@/components/foot.vue";

export default {
  name: 'signin',
  components: {
    Foot,
    mobile,
    Navbar,
  },
  data() {
    return {
      isMobileVisible: false,
    };
  },
  methods: {
    toggleMobile() {
      this.isMobileVisible = !this.isMobileVisible;
    },
  },
}
</script>

<style scoped>

.account_ .blue{
  margin-left: 5px;
}

.form{
  display: flex;
  width: 100%;
  height: 100svh;
  justify-content: center;
  align-items: center;
}

form{
  display: flex;
  width: 100%;
  max-width: 600px;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 0 40px;
}

form h1{
  font-size: 35px;
  font-family: 'Lexend Deca', sans-serif;
  margin-bottom: 20px;
}

.desc{
  margin-bottom: 50px;
  text-align: center;
}

form input{
  display: flex;
  width: 100%;
  max-width: 400px;
  height: 50px;
  border-radius: 20px;
  background: none;
  outline: none;
  border: solid 1px #8a8a8a;
  padding: 0 20px;
  margin-bottom: 15px;
}

form button{
  display: flex;
  width: 120px;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  border: solid 1px #8a8a8a;
  margin-bottom: 20px;
}

form a{
  font-size: 15px;
  color: #0283c5;
}

form a:hover{
  text-decoration: underline;
}

.save{
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

.save input{
  display: flex;
  width: 15px;
  height: 15px;
  margin-bottom: 0;
  margin-right: 5px;
}

.dual{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.dual input{
  max-width: 190px;
}

.space{
  display: flex;
  width: 20px;
  min-width: 20px;
}

@media  (max-width: 500px) {
  form{
    padding-top: 50px;
  }
}
</style>
